import React, { Fragment, useState, useEffect } from "react";
import SidePanel from "../utilities/components/SidePanel";
import Spinner from "../utilities/components/Spinner";
import { apiCall } from "../utilities/apiCall";
import { showAlert } from "../utilities/alertmessage";
import { numberWithCommas } from "../utilities/commonHelper";
import AppMessages from "../utilities/Messages";
const profileMessages = AppMessages.profiles;
export default function EmailStats(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState({});

  const loadStats = () => {
    setIsLoading(true);
    apiCall("/api/fetchemailstats")
      .then((res) => {
        setIsLoading(false);
        if (res.Success) {
          setStats(res);
        } else {
          showAlert({
            msg_type: "error",
            msg_text: profileMessages.errMessage
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        showAlert({
          msg_type: "error",
          msg_text: profileMessages.errMessage
        });
      });
  };

  useEffect(() => {
    loadStats();
  }, []);

  return (
    <SidePanel title="Email Stats" onClose={props.collapseEmailStats}>
      {isLoading ? (
        <Spinner className="container-center" style={{ width: "150px", height: "110px" }} />
      ) : (
        <Fragment>
          <table className="table table-bordered table-striped table-sm mt-5">
            <tbody>
              <tr key="1">
                <td className="email-status valid-email">&nbsp;Valid</td>
                <td>{numberWithCommas(stats.Valid)}</td>
              </tr>
              <tr key="2">
                <td className="email-status invalid-email">&nbsp;Invalid</td>
                <td>{numberWithCommas(stats.Invalid)}</td>
              </tr>
              <tr key="3">
                <td className="email-status unknown-email">&nbsp;Unknown</td>
                <td>{numberWithCommas(stats.Unknown)}</td>
              </tr>
              <tr key="4">
                <td className="email-status pending-email">&nbsp;Pending</td>
                <td>{numberWithCommas(stats.Pending)}</td>
              </tr>
            </tbody>
          </table>
          <div className="text-center">
            <button className="btn btn-sm" onClick={loadStats}>
              <i className="fa fa-refresh" aria-hidden="true" /> Refresh
            </button>
          </div>
        </Fragment>
      )}
    </SidePanel>
  );
}
