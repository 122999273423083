import React from "react";
import { Switch } from "react-router-dom";
import StandAloneClientRoutes from "./StandAloneClientRoutes";
import Layoutwithoutmenu from "../utilities/components/layoutwithoutmenu";

const StandAloneClientPageLayout = () => {
  // useEffect(() => {
  //   //Adding styles to DOM for below routes
  //   const stylesheets = [
  //     `${process.env.PUBLIC_URL}/css/bootstrap.min.css`,
  //     `https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/toastr.min.css`
  //   ];
  //   stylesheets.forEach((style) => {
  //     const link = document.createElement("link");
  //     link.rel = "stylesheet";
  //     link.href = style;
  //     document.head.appendChild(link);
  //   });
  // }, []);
  return (
    <Switch>
      {StandAloneClientRoutes.map((route, index) => {
        return (
          <Layoutwithoutmenu path={`/trial${route.path}`} key={`/trial${route.path}`} component={route.component} />
        );
      })}
    </Switch>
  );
};
export default StandAloneClientPageLayout;
