import React, { cloneElement } from "react";
import { userRoles } from "../enums";
import { useAccessControl } from "../commonhooks";

const handleDisable = (children) => {
  return cloneElement(children, {
    onClickCapture: (e) => {
      e.stopPropagation();
    },
    className: children.props.className ? children.props.className + " disabled-feature" : "disabled-feature",
    title: "Accessed only by Admin Users"
  });
};

export const AdminOnly = (props) => {
  const { children, disable, isSaOnly, actionType } = props;
  //console.log(actionType);
  const { userHasAccess, userRole } = useAccessControl(actionType);

  if (userRole === userRoles.SUPERADMIN) {
    return children;
  } else if (isSaOnly) {
    return null;
  } else {
    if (userRole === userRoles.ADMIN) {
      return children;
    } else if (userRole === userRoles.USER) {
      if (userHasAccess) {
        return children;
      } else {
        if (disable) {
          return handleDisable(children);
        } else {
          return null;
        }
      }
    }
  }
};
