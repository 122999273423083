import React from "react";
import { Route } from "react-router-dom";
import LazyComponent from "./LazyComponent";

const Layoutwithoutmenu = ({ component: Component, ...rest }) => {
  return (
    <div id="content" role="main" className="content">
      <div>
        <Route exact {...rest} render={LazyComponent(Component)} />
      </div>
    </div>
  );
};
export default Layoutwithoutmenu;
