import { lazy } from "react";

const GetStartedPage = lazy(() => import("../standAloneClient/components/GetStarted/GetStarted"));
const SignUpPage = lazy(() => import("../standAloneClient/index"));
const SubscriptionPage = lazy(() => import("../standAloneClient/components/SubscriptionPage/SubscriptionPage"));
const SetupProgressPage = lazy(() => import("../standAloneClient/components/SetupPage/SetupProgressPage"));
const NotFoundPage = lazy(() => import("../standAloneClient/components/NotFoundPage/NotFoundPage"));
const LoginPage = lazy(() => import("../standAloneClient/components/Login/Login"));
const ActivationSuccessPage = lazy(() => import("../standAloneClient/components/Auth/ActivationSuccess"));
const RequestAccessPage = lazy(() => import("../standAloneClient/components/Auth/RequestAccess"));
const ForgotPassword = lazy(() => import("../standAloneClient/components/Auth/ForgotPassword"));
const ResendActivationPage = lazy(() => import("../standAloneClient/components/Auth/ResendActivation"));
const ResetPasswordPage = lazy(() => import("../standAloneClient/components/Auth/ResetPassword"));

const StandAloneClientRoutes = [
  {
    path: "/getstarted",
    component: GetStartedPage
  },
  {
    path: "/trialrequested",
    component: SignUpPage
  },
  {
    path: "/subscriptionplans",
    component: SubscriptionPage
  },
  {
    path: "/setup",
    component: SetupProgressPage
  },
  {
    path: "/NotFound",
    component: NotFoundPage
  },
  {
    path: "/login",
    component: LoginPage
  },
  {
    path: "/password",
    component: ResendActivationPage
  },
  {
    path: "/activationsuccess",
    component: ActivationSuccessPage
  },
  {
    path: "/resetpassword",
    component: ResetPasswordPage
  },
  {
    path: "/requestaccess",
    component: RequestAccessPage
  },
  {
    path: "/forgotpassword",
    component: ForgotPassword
  }
];
export default StandAloneClientRoutes;
