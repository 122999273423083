import React from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import LayoutWithMKMMenu from "./utilities/components/layoutwithmenu";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import StandAloneClientPageLayout from "./Routes/StandAloneClientPageLayout";

function EmailService() {
  return (
    <DndProvider backend={HTML5Backend}>
      <BrowserRouter>
        <Switch>
          <Route path="/trial" render={() => <StandAloneClientPageLayout />} />
          <Route path="/" component={LayoutWithMKMMenu} />
        </Switch>
      </BrowserRouter>
    </DndProvider>
  );
}

export default EmailService;
