import { useLayoutEffect, useRef, useState, useEffect, useCallback } from "react";
import { userRoles, OTHER_MERGE_TAGS, ORDER_MERGE_TAGS, SiteTypeActions } from "./enums";
import { apiCall } from "./apiCall";
import { showAlert } from "./alertmessage";
import AppMessages from "./Messages";
import "intersection-observer";
import { siteTypeBasedController } from "./siteTypeHelper";
const accessMessages = AppMessages.adminAccess;

export const useDisableBodyScroll = function () {
  useLayoutEffect(() => {
    const htmlEle = document.querySelector("html");
    htmlEle.classList.remove("applyscroll");
    htmlEle.classList.add("removescroll");
    return () => {
      htmlEle.classList.remove("removescroll");
      htmlEle.classList.add("applyscroll");
    };
  }, []);
};

export const transformString = function (str) {
  let indexOfEqual = str.indexOf("=");
  indexOfEqual = indexOfEqual === -1 ? str.length : indexOfEqual;

  let splitIndex = Math.ceil(str.length / 2);
  let transformedString =
    str.substring(0, splitIndex).split("").reverse().join("") +
    str.substring(splitIndex, indexOfEqual).split("").reverse().join("") +
    str.substring(indexOfEqual, str.length);
  return transformedString;
};

function arrayBufferToBase64(buffer) {
  let binary = "";
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export const encode = function (str) {
  return transformString(arrayBufferToBase64(new TextEncoder().encode(str)));
  // return transformString(btoa(str));
};

function base64ToArrayBuffer(base64) {
  let binaryString = atob(base64);
  let bytes = new Uint8Array(binaryString.length);
  for (var i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}
export const decode = function (str) {
  return new TextDecoder().decode(base64ToArrayBuffer(transformString(str)));
  // return atob(transformString(str));
};

// Access Control
export const useAccessControl = (actionType) => {
  const accessControl = useRef();
  if (accessControl.current == null) {
    let accessControlData = decode(sessionStorage.getItem("ACCESS_CONTROL"));
    if (accessControlData != null) {
      accessControl.current = JSON.parse(accessControlData);
    } else {
      accessControl.current = { accessControlList: [], userRole: userRoles.USER };
    }
  }

  let access = false;
  const actionAccessControl = function (val) {
    return val.accessControlList.find((d) => d.actionType === actionType);
  };

  const getUpdatedList = function (val, updated) {
    return val.accessControlList.map((item) => {
      if (item.actionType === actionType) {
        return { actionType: actionType, users: updated === true ? [userRoles.USER] : [] };
      } else {
        return item;
      }
    });
  };

  if (
    actionType !== undefined &&
    actionAccessControl(accessControl.current) &&
    actionAccessControl(accessControl.current).users.includes(userRoles.USER)
  ) {
    access = true;
  }

  const updateAccess = function (updateValue) {
    apiCall(`/api/updateAccessControl`, "POST", {
      action: actionType,
      userAccess: updateValue
    }).then((respJson) => {
      if (respJson.success == true) {
        // For Updating Session Value
        if (updateValue == true) {
          if (actionAccessControl(accessControl.current)) {
            accessControl.current.accessControlList = getUpdatedList(accessControl.current, updateValue);
          } else {
            const updateVal = { actionType: actionType, users: [userRoles.USER] };
            accessControl.current.accessControlList.push(updateVal);
          }
        } else {
          if (actionAccessControl(accessControl.current)) {
            accessControl.current.accessControlList = getUpdatedList(accessControl.current, updateValue);
          } else {
            const updateVal = { actionType: actionType, users: [] };
            accessControl.current.accessControlList.push(updateVal);
          }
        }

        let accessControlUpdate = JSON.stringify(accessControl.current);
        // console.log(`after ${accessControlUpdate}`);

        accessControlUpdate = encode(accessControlUpdate);
        sessionStorage.setItem("ACCESS_CONTROL", accessControlUpdate);

        if (updateValue) {
          showAlert({
            msg_text: accessMessages.enabledConfirmation,
            msg_type: "success"
          });
        } else {
          showAlert({
            msg_text: accessMessages.disabledConfirmation,
            msg_type: "success"
          });
        }
      } else {
        showAlert({
          msg_text: accessMessages.errorMsg,
          msg_type: "error"
        });
      }
    });
  };

  return {
    userHasAccess: access,
    updateAccess,
    userRole: accessControl.current.userRole
  };
};

// Merge Fields
export const mergeFields = async (fieldType) => {
  let fieldsList = [];
  try {
    fieldsList = await apiCall(`/api/getFields?fieldTypes=${fieldType}`, "GET");
  } catch (error) {}
  fieldsList = fieldsList.map((item) => {
    return {
      name: item.displayName,
      //value: item.mergeField,
      value: item.fieldName === "emailId" ? `&lt;% Email %&gt;` : `&lt;% ${item.fieldName} %&gt;`,
      key: item.fieldName,
      defaultMergeTag: item.defaultMergeTag
    };
  });
  fieldsList = [...fieldsList, ...OTHER_MERGE_TAGS];

  const allowedForClient = await siteTypeBasedController(SiteTypeActions.ISALLOWED);
  fieldsList = allowedForClient
    ? [...fieldsList, ...OTHER_MERGE_TAGS, ...ORDER_MERGE_TAGS]
    : [...fieldsList, ...OTHER_MERGE_TAGS];

  // Remove duplicates
  let finalList = fieldsList.reduce((acc, current) => {
    const x = acc.find((item) => item.key === current.key);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  return finalList;
};

export const useOnClickOutside = (handler) => {
  const ref = useRef(null);

  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      console.log("ref", ref.current);
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [handler]);

  return ref;
};
export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
};

//custom hook to return prev state value
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  return ref.current;
}

export function useLocalStorage(key, defaultValue) {
  const [value, setValue] = useState(() => localStorage.getItem(key) || defaultValue);

  useEffect(() => {
    localStorage.setItem(key, value);
  }, [key, value]);

  return [value, setValue];
}
export const useIntersectionObserver = ({ ref, isLoading, isObserverChanged }) => {
  const [observer, setObserver] = useState(null);
  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [observer, ref]);
  const intersectionObserver = useCallback(
    (entries) => {
      if (entries[0].isIntersecting && !isLoading) {
        isObserverChanged();
      }
    },
    [isLoading, isObserverChanged]
  );
  useEffect(() => {
    const options = {
      root: null, // Use the page as the root
      rootMargin: "0px",
      threshold: 0
    };
    const obser = new IntersectionObserver(intersectionObserver, options);
    setObserver(obser);
    return () => {
      obser.disconnect();
    };
  }, [intersectionObserver]);
};
