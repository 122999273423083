import { apiCall } from "./apiCall";
import { decode } from "./commonhooks";
import { SiteTypeActions, SiteTypes } from "./enums";
export async function siteTypeBasedController(type, data) {
  /**
   * Checking for site type and restricting, restricting directly for few (MKM+CRM)
   * for few,  we are calling the api for limit check for clients.
   */
  const siteTypeSessionData = decode(sessionStorage.getItem("ACCESS_CONTROL"));
  if (siteTypeSessionData != null) {
    const { siteType } = JSON.parse(siteTypeSessionData);
    const isAllowed = siteType === SiteTypes.STANDALONECRM ? false : true;
    switch (type) {
      case SiteTypeActions.ISALLOWED:
        return isAllowed;
      case SiteTypeActions.LIMITCHECK:
        const response = await apiCall(`/api/checkPlanLimit?moduleName=${data.moduleName}`);
        if (response) return response;
        break;
      default:
        return true;
    }
  } else return true;
}
