import React, { useEffect, useState } from "react";
import { Route, withRouter } from "react-router-dom";
import queryString from "query-string";
import EsSideMenu from "../../utilities/components/essidemenu";
import LazyComponent from "./LazyComponent";

const LayoutComponent = ({ component: Component, isesEnabled, subscriptionsEnabled, repNotificationsOnly, ...rest }) => {

  useEffect(() => {
    const values = queryString.parse(rest.location.search);
    let ESDisabled = false,
      MKMDisabled = false;
    if ("ESFE" in values && values.ESFE == 0) {
      ESDisabled = true;
    } else if ("MKMUA" in values && values.MKMUA == 0) {
      MKMDisabled = true;
    }

    if (ESDisabled || MKMDisabled) {
      //Navigate to setup page and display the message
      const replace = rest.history.replace;
      replace({
        pathname: "/setupPage",
        state: {
          MKMDisabled
        }
      });
      return;
    }

  }, [rest.history.replace, rest.location.search]);

  return (
    //add loading indiactor
    <div>
      {isesEnabled && (
        <React.Fragment>
          <EsSideMenu
            handleLiClick={rest.handleLiClick}
            showMoreMenu={rest.showMoreMenu}
            displayEmailStats={rest.displayEmailStats}
            collapseEmailStats={rest.collapseEmailStats}
            subscriptionsEnabled={subscriptionsEnabled}
            repNotificationsOnly={repNotificationsOnly}
          />
          <div id="content" role="main" className="content">
            <div className="">
              <div className="container-fluid">
                <Route exact {...rest} render={LazyComponent(Component)} />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}

    </div>
  );
};
export default withRouter(LayoutComponent);
