import React from "react";

const Spinner = React.memo(
  React.forwardRef(({ className, style }, ref) => (
    <div
      ref={ref}
      className={"row " + (className ? className : "")}
      style={{ display: "flex", justifyContent: "center" }}
    >
      <img src="/images/loading.gif" alt="Loading..." style={Object.assign({ height: "60px" }, style)} />
    </div>
  ))
);

export default Spinner;
